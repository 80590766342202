import * as Types from '../../../../.cache/__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InfoBannerQueryVariables = Types.Exact<{
  locale: Types.Locale;
}>;


export type InfoBannerQuery = { __typename?: 'Query', infoBanner?: string | null };


export const InfoBannerDocument = gql`
    query InfoBanner($locale: Locale!) {
  infoBanner(locale: $locale)
}
    `;

/**
 * __useInfoBannerQuery__
 *
 * To run a query within a React component, call `useInfoBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoBannerQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useInfoBannerQuery(baseOptions: Apollo.QueryHookOptions<InfoBannerQuery, InfoBannerQueryVariables> & ({ variables: InfoBannerQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InfoBannerQuery, InfoBannerQueryVariables>(InfoBannerDocument, options);
      }
export function useInfoBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfoBannerQuery, InfoBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InfoBannerQuery, InfoBannerQueryVariables>(InfoBannerDocument, options);
        }
export function useInfoBannerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InfoBannerQuery, InfoBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InfoBannerQuery, InfoBannerQueryVariables>(InfoBannerDocument, options);
        }
export type InfoBannerQueryHookResult = ReturnType<typeof useInfoBannerQuery>;
export type InfoBannerLazyQueryHookResult = ReturnType<typeof useInfoBannerLazyQuery>;
export type InfoBannerSuspenseQueryHookResult = ReturnType<typeof useInfoBannerSuspenseQuery>;
export type InfoBannerQueryResult = Apollo.QueryResult<InfoBannerQuery, InfoBannerQueryVariables>;