import * as Types from '../../../../.cache/__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserNotificationCountsQueryVariables = Types.Exact<{
  user: Types.Scalars['ID']['input'];
}>;


export type UserNotificationCountsQuery = { __typename?: 'Query', user?: { __typename?: 'User', id?: string | null, firstname?: string | null, lastname?: string | null, photo?: string | null, accountType?: Types.AccountType | null, timeAccountExpiration?: number | null, marketingConsentStatus?: Types.MarketingConsentStatus | null, menuCounts?: { __typename?: 'MenuCounts', notifications?: number | null, conversations?: number | null, favourites?: number | null, watchdogs?: number | null } | null } | null };


export const UserNotificationCountsDocument = gql`
    query UserNotificationCounts($user: ID!) {
  user(id: $user) {
    id
    firstname
    lastname
    photo(filter: USER_PHOTO)
    accountType
    timeAccountExpiration
    marketingConsentStatus
    menuCounts {
      notifications
      conversations
      favourites
      watchdogs
    }
  }
}
    `;

/**
 * __useUserNotificationCountsQuery__
 *
 * To run a query within a React component, call `useUserNotificationCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationCountsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUserNotificationCountsQuery(baseOptions: Apollo.QueryHookOptions<UserNotificationCountsQuery, UserNotificationCountsQueryVariables> & ({ variables: UserNotificationCountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNotificationCountsQuery, UserNotificationCountsQueryVariables>(UserNotificationCountsDocument, options);
      }
export function useUserNotificationCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNotificationCountsQuery, UserNotificationCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNotificationCountsQuery, UserNotificationCountsQueryVariables>(UserNotificationCountsDocument, options);
        }
export function useUserNotificationCountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserNotificationCountsQuery, UserNotificationCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserNotificationCountsQuery, UserNotificationCountsQueryVariables>(UserNotificationCountsDocument, options);
        }
export type UserNotificationCountsQueryHookResult = ReturnType<typeof useUserNotificationCountsQuery>;
export type UserNotificationCountsLazyQueryHookResult = ReturnType<typeof useUserNotificationCountsLazyQuery>;
export type UserNotificationCountsSuspenseQueryHookResult = ReturnType<typeof useUserNotificationCountsSuspenseQuery>;
export type UserNotificationCountsQueryResult = Apollo.QueryResult<UserNotificationCountsQuery, UserNotificationCountsQueryVariables>;