import {AdvertImportType, Currency, Equipped, Maybe, OfferType, Region, RegionSubtype} from '.cache/__types__';

export const defaultPT = {
    pageType: 'other',
    price: null,
    currency: null,
    status: null,
    equipped: null,
    geo1: null,
    geo2: null,
    geo3: null,
    geo4: null,
};

export type DataLayerType = Record<string, any>;

export const dataLayerPush = (data: DataLayerType) => {
    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer.push(data);
};

export const dataLayerPushPageView = (
    lastUrl?: string,
    dlObject: DataLayerType = {},
    dlObjectAfter?: DataLayerType,
    skipDL?: boolean,
    skipPageType?: boolean,
) => {
    if (skipPageType) {
        // console.log('DataLayerPush pageType skipped');
    } else {
        // console.log('DataLauerPush pageType fired');
        const dl = Object.assign({}, defaultPT, dlObject);
        dataLayerPush(dl);
    }

    if (skipDL) {
        // console.log('DataLayerPush ga.page skipped');
    } else {
        // console.log('DataLauerPush ga.page fired');
        dataLayerPush({
            event: 'ga.page',
            location: window.location.href,
            path: window.location.pathname + (window.location.search ? window.location.search : ''),
            title: document.title,
            referrer: lastUrl ?? document.referrer,
        });
    }

    if (dlObjectAfter) {
        dataLayerPush(dlObjectAfter);
    }
};

export const trackDlClickContact = (category: string, action: string) => {
    dataLayerPush({
        event: 'reakce_inzerat',
        category,
        action,
    });
};

export type AdvertDataLayerType = {
    offerType?: Maybe<OfferType>;
    type?: Maybe<AdvertImportType>;
    price?: Maybe<number>;
    currency?: Maybe<Currency>;
    active?: Maybe<boolean>;
    equipped?: Maybe<Equipped>;
    regionTree?: Maybe<Maybe<Region>[]>;
};

export const getAdvertDataLayer = (advert: AdvertDataLayerType) => {
    return {
        pageType:
            'inzerat_' +
            (advert.type && advert.type !== AdvertImportType.Undefined ? advert.type : advert.offerType!).toLowerCase().replace(/_/, ''),
        price: advert.price,
        currency: advert.currency,
        status: advert.active ? 'active' : 'inactive',
        equipped: advert.equipped?.toLowerCase() ?? null,
        geo1: advert.regionTree?.find((r) => r?.subType === RegionSubtype.Region)?.name ?? null,
        geo2: advert.regionTree?.find((r) => r?.subType === RegionSubtype.County)?.name ?? null,
        geo3: null,
        geo4: null,
    };
};
