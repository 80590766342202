import {FormattedMessage, IntlShape} from 'react-intl';
import {EstateType} from '.cache/__types__';
import {LocaleType, LocaleEnum} from 'src/types/general';
import React from 'react';

export const translations = {
    [EstateType.Byt]: <FormattedMessage defaultMessage="Byt" />,
    [EstateType.Dum]: <FormattedMessage defaultMessage="Dům" />,
    [EstateType.Pozemek]: <FormattedMessage defaultMessage="Pozemek" />,
    [EstateType.Garaz]: <FormattedMessage defaultMessage="Garáž" />,
    [EstateType.Kancelar]: <FormattedMessage defaultMessage="Kancelář" />,
    [EstateType.NebytovyProstor]: <FormattedMessage defaultMessage="Nebytový prostor" />,
    [EstateType.RekreacniObjekt]: <FormattedMessage defaultMessage="Chaty a chalupy" />,
    [EstateType.Undefined]: <FormattedMessage defaultMessage="Nezáleží" />,
};

export const translationsArray = [
    {value: EstateType.Byt, label: <FormattedMessage defaultMessage="Byt" />},
    {value: EstateType.Dum, label: <FormattedMessage defaultMessage="Dům" />},
    {value: EstateType.Pozemek, label: <FormattedMessage defaultMessage="Pozemek" />},
    {value: EstateType.Garaz, label: <FormattedMessage defaultMessage="Garáž" />},
    {value: EstateType.Kancelar, label: <FormattedMessage defaultMessage="Kancelář" />},
    {value: EstateType.NebytovyProstor, label: <FormattedMessage defaultMessage="Nebytový prostor" />},
    {value: EstateType.RekreacniObjekt, label: <FormattedMessage defaultMessage="Chaty a chalupy" />},
    {value: EstateType.Undefined, label: <FormattedMessage defaultMessage="Nezáleží" />},
];

export const translationsString = {
    [EstateType.Undefined]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Nevyplněno'}),
    [EstateType.Byt]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Byt'}),
    [EstateType.Dum]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Dům'}),
    [EstateType.Pozemek]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Pozemek'}),
    [EstateType.Garaz]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Garáž'}),
    [EstateType.Kancelar]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Kancelář'}),
    [EstateType.NebytovyProstor]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Nebytový prostor'}),
    [EstateType.RekreacniObjekt]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Chaty a chalupy'}),
};

export const genitiveTranslations: Record<string, React.ReactElement> = {
    [EstateType.Undefined]: <FormattedMessage defaultMessage="Nevyplněno" />,
    [EstateType.Byt]: <FormattedMessage defaultMessage="Bytu" />,
    [EstateType.Dum]: <FormattedMessage defaultMessage="Domu" />,
    [EstateType.Pozemek]: <FormattedMessage defaultMessage="Pozemku" />,
    [EstateType.Garaz]: <FormattedMessage defaultMessage="Garáže" />,
    [EstateType.Kancelar]: <FormattedMessage defaultMessage="Kanceláře" />,
    [EstateType.NebytovyProstor]: <FormattedMessage defaultMessage="Nebytového prostoru" />,
    [EstateType.RekreacniObjekt]: <FormattedMessage defaultMessage="Chat a chalup" />,
};

export const imperativeTranslations = {
    [EstateType.Undefined]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Nevyplněno'}),
    [EstateType.Byt]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Byt'}),
    [EstateType.Dum]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Dům'}),
    [EstateType.Pozemek]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Pozemek'}),
    [EstateType.Garaz]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Garáž'}),
    [EstateType.Kancelar]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Kancelář'}),
    [EstateType.NebytovyProstor]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Nebytový prostor'}),
    [EstateType.RekreacniObjekt]: (intl: IntlShape) => intl.formatMessage({defaultMessage: 'Chaty a chalupy'}),
};

export const getTranslationString = (intl: IntlShape, key: EstateType) => imperativeTranslations[key](intl);
export const getImperativeTranslation = (intl: IntlShape, key: EstateType) => imperativeTranslations[key](intl);

export const localizedListSlugs: Record<LocaleType, Record<Exclude<EstateType, EstateType.Undefined>, string>> = {
    [LocaleEnum.cs]: {
        [EstateType.Byt]: 'byt',
        [EstateType.Dum]: 'dum',
        [EstateType.Pozemek]: 'pozemek',
        [EstateType.Garaz]: 'garaz',
        [EstateType.Kancelar]: 'kancelar',
        [EstateType.NebytovyProstor]: 'nebytovy-prostor',
        [EstateType.RekreacniObjekt]: 'chata-chalupa',
    },
    [LocaleEnum.sk]: {
        [EstateType.Byt]: 'byt',
        [EstateType.Dum]: 'dom',
        [EstateType.Pozemek]: 'pozemok',
        [EstateType.Garaz]: 'garaz',
        [EstateType.Kancelar]: 'kancelaria',
        [EstateType.NebytovyProstor]: 'nebytovy-priestor',
        [EstateType.RekreacniObjekt]: 'rekreacny-objekt',
    },
    [LocaleEnum.en]: {
        [EstateType.Byt]: 'flat',
        [EstateType.Dum]: 'house',
        [EstateType.Pozemek]: 'plot',
        [EstateType.Garaz]: 'garage',
        [EstateType.Kancelar]: 'office',
        [EstateType.NebytovyProstor]: 'non-residential-property',
        [EstateType.RekreacniObjekt]: 'recreational-property',
    },
};

export const ESTATE_TYPE_LIST = Object.values(EstateType).filter((item) => item !== EstateType.Undefined);

export const label = <FormattedMessage defaultMessage="Nemovitost" />;

export const listDefaultType = EstateType.Byt;
