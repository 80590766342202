import {FC, HTMLProps, ReactNode, useCallback, useEffect, useMemo} from 'react';
import {useField} from 'formik';
import {Form} from '@components/Form';
import {InputGroup} from '@components/InputGroup';
import {FormControlProps} from 'react-bootstrap';
import {getCountries, getCountryCallingCode, parsePhoneNumber} from 'libphonenumber-js';
import {useLocale} from '@utils/locale';
import {LocaleEnum} from '../../types/general';
import {flags} from '@constants/Country';
import {useIntl} from 'react-intl';

type Props = {
    className?: string;
    label?: ReactNode;
    placeholder?: string;
    required?: boolean;
} & ({label: ReactNode} | {placeholder: string}) &
    FormControlProps &
    Omit<HTMLProps<HTMLInputElement>, 'label'>;

export type PhoneInputProps = Props & {name: string};

const flagMap: Record<string, string> = getCountries().reduce<Record<string, string>>((carry, item) => {
    if (typeof flags[item.toLowerCase()] === 'undefined') {
        return carry;
    }

    // FIXME https://gitlab.com/catamphetamine/libphonenumber-js/-/issues/110
    switch (item.toLowerCase()) {
        case 'va':
            carry['379'] = flags[item.toLowerCase()];
            break;

        default:
            carry[getCountryCallingCode(item)] = flags[item.toLowerCase()];
            break;
    }

    return carry;
}, {});

const prefixes = getCountries()
    .map((c) => {
        switch (c.toLowerCase()) {
            // FIXME https://gitlab.com/catamphetamine/libphonenumber-js/-/issues/110
            case 'va':
                return '+379';

            default:
                return '+' + getCountryCallingCode(c);
        }
    })
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort();
const preferredPrefixes = ['+420', '+421'];

export const PhoneInput: FC<React.PropsWithChildren<PhoneInputProps>> = ({
    className,
    label,
    placeholder,
    required,
    name,
    ...innerProps
}) => {
    const [field, meta, helpers] = useField(name);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {value, onChange, name: _, ...restField} = field;
    const setFieldValue = helpers.setValue;
    const setTouched = helpers.setTouched;
    const locale = useLocale();
    const intl = useIntl();

    const isTouched = meta.touched;
    const ownErrors = meta.error;
    const hasErrors = !!ownErrors;

    const prefix = useMemo(() => {
        try {
            const number = parsePhoneNumber(value, locale === LocaleEnum.sk ? 'SK' : 'CZ');
            return '+' + number.countryCallingCode;
        } catch (e) {
            return prefixes.find((p) => value.startsWith(p)) ?? '+420';
        }
    }, [value, locale]);
    const number = useMemo(() => value.substring(prefix.length), [value, prefix]);

    useEffect(() => {
        if (number.startsWith(prefix)) {
            setFieldValue(number);
        }
    }, [setFieldValue, number, prefix]);

    const onPrefixChange = useCallback(
        (e: any) => {
            setTouched(true);
            setFieldValue(e.target.value + number);
        },
        [setFieldValue, setTouched, number],
    );

    const onValueChange = useCallback(
        (e: any) => {
            setTouched(true);
            setFieldValue(prefix + e.target.value);
        },
        [setFieldValue, setTouched, prefix],
    );

    const preferred = useMemo(() => prefixes.filter((p) => preferredPrefixes.includes(p)), []);
    const other = useMemo(() => prefixes.filter((p) => !preferredPrefixes.includes(p)), []);

    return (
        <Form.Group className={className}>
            <Form.Label visuallyHidden={!label} htmlFor={name + '-number'}>
                {label ?? placeholder}
            </Form.Label>
            <InputGroup hasValidation={isTouched && hasErrors}>
                <InputGroup.Text>
                    <Form.Select
                        id={name + '-prefix'}
                        name={name + '-prefix'}
                        autoComplete={'tel-country-code'}
                        value={prefix}
                        onChange={onPrefixChange}
                        onBlur={() => setTouched(true)}
                    >
                        <optgroup label={intl.formatMessage({defaultMessage: 'Preferované'})}>
                            {preferred.map((p) => (
                                <option key={p} value={p}>
                                    {p.padEnd(4, '\u2007')} {flagMap[p.substring(1)]}
                                </option>
                            ))}
                        </optgroup>
                        <optgroup label={intl.formatMessage({defaultMessage: 'Ostatní'})}>
                            {other.map((p) => (
                                <option key={p} value={p}>
                                    {p.padEnd(4, '\u2007')} {flagMap[p.substring(1)]}
                                </option>
                            ))}
                        </optgroup>
                    </Form.Select>
                </InputGroup.Text>
                <Form.Control
                    placeholder={placeholder}
                    id={name + '-number'}
                    name={name + '-number'}
                    value={number}
                    autoComplete={'tel-national'}
                    {...restField}
                    {...innerProps}
                    isInvalid={isTouched && hasErrors}
                    onChange={onValueChange}
                    onBlur={() => setTouched(true)}
                />
                {required && <InputGroup.Required>*</InputGroup.Required>}
                {isTouched && hasErrors && <Form.Control.Feedback type={'invalid'}>{ownErrors}</Form.Control.Feedback>}
            </InputGroup>
        </Form.Group>
    );
};
