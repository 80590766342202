import {useCallback, useEffect, useMemo, useRef} from 'react';
import {Typography} from '@components/Typography';
import {FormattedMessage, useIntl} from 'react-intl';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import * as Yup from 'yup';
import {Form} from '@components/Form';
import {Button} from '@components/Button';
import {Divider} from '@components/Divider';
import {getCsrfToken, signIn} from 'next-auth/react';
import {InfoText} from '@components/InfoText';
import {Box} from '@components/Box';
import {Input} from '@liveComponents/Field';
import {useToaster} from '@utils/toast';
import {getLocalizedRoute, routes} from '@utils/routes';
import {useLocale} from '@utils/locale';
import {useRouter} from 'next/router';
import {HiddenInput} from '@liveComponents/Field/HiddenInput';
import {InfoTooltip} from '@components/InfoTooltip';
import Link from 'next/link';
import {useMQ} from '@hooks/useMQ';
import {CheckboxInput} from '@liveComponents/Field/CheckboxInput';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {useNewsletterSubscribeMutation} from '@gql/mutation/user/newsletterSubscribe.gql';
import {MarketingConsentOrigin} from '.cache/__types__';
import {RecaptchaClaim} from '@liveComponents/RecaptchaClaim';
import {PasswordInput} from '@liveComponents/Field/PasswordInput';
import {SubmitButton} from '@liveComponents/Field/SubmitButton';
import {LoginRegisterToggle, LoginRegisterToggleEnum} from './LoginRegisterToggle';
import {TouchPointEnum, getTouchPoint} from './utils';
import {dataLayerPush} from '@utils/gtm';

export type LoginFormProps = {
    id: TouchPointEnum;
    onSuccess?: () => void;
    onForgottenPassword?: () => void;
    onRegister?: () => void;
    redirect?: boolean;
    callbackUrl?: string;
    personify?: string;
};

type LoginFormValues = {
    csrfToken: string;
    username: string;
    password: string;
    callbackUrl: string;
    target: string;
    redirect: string;
    json: string;
    marketing: boolean;
};

export const LoginForm = ({id, redirect, callbackUrl, onSuccess, onRegister, onForgottenPassword, personify}: LoginFormProps) => {
    const intl = useIntl();
    const toaster = useToaster();
    const locale = useLocale();
    const router = useRouter();
    const {executeRecaptcha} = useGoogleReCaptcha();
    const isMdDown = useMQ('mdDown');
    const form = useRef<FormikProps<LoginFormValues>>(null);

    useEffect(() => {
        dataLayerPush({
            event: 'prihlaseni_zobrazeni_formulare',
            touchpoint: getTouchPoint(id),
        });
    }, [id]);

    const [subscribe] = useNewsletterSubscribeMutation();

    useEffect(() => {
        void getCsrfToken().then((token) => {
            void form.current?.setValues({...form.current.values, csrfToken: token ?? ''});
        });
    }, []);

    const domain = useMemo(() => router.domainLocales?.find((d) => d.defaultLocale === locale)!, [router, locale]);
    const targetUrl = useMemo(
        () => `http${domain.http ? '' : 's'}://${domain.domain}${router.basePath}` + (callbackUrl ?? router.asPath),
        [callbackUrl, domain, router.basePath, router.asPath],
    );

    useEffect(() => {
        void form.current?.setValues({...form.current.values, callbackUrl: targetUrl, target: targetUrl});
    }, [targetUrl]);

    const processLoginSubmit = useCallback(
        async (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
            if (values.marketing && values.username) {
                if (!executeRecaptcha) {
                    toaster.toast({
                        message: (
                            <FormattedMessage defaultMessage="Stránka ještě není plně načtena. Vyčkejte prosím jejího načtení a zkuste to znovu." />
                        ),
                        header: <FormattedMessage defaultMessage="Upozornění" />,
                        bg: 'warning',
                        color: 'white',
                        duration: 5000,
                    });
                    actions.setSubmitting(false);
                    return;
                }
                await executeRecaptcha('consent').then((token) =>
                    subscribe({
                        variables: {
                            email: values.username,
                            origin: MarketingConsentOrigin.Login,
                            recaptcha: token,
                        },
                    }),
                );
            }

            signIn<'credentials'>(personify ? 'personification' : 'credentials', {
                username: values.username,
                redirect: false,
                password: values.password,
                target: personify,
                callbackUrl: targetUrl,
            })
                .then((response) => {
                    actions.setSubmitting(false);
                    if (response?.error) {
                        const message =
                            response.error === 'Invalid credentials'
                                ? intl.formatMessage({defaultMessage: 'Zadaná kombinace jména a hesla není platná'})
                                : response.error === 'Server is in maintenance'
                                  ? intl.formatMessage({defaultMessage: 'Server je momentálně nedostupný, zkuste to prosím později'})
                                  : intl.formatMessage({defaultMessage: 'Při přihlašování došlo k chybě'});
                        actions.setErrors({password: message});
                    } else {
                        toaster.toast({
                            bg: 'success',
                            message: intl.formatMessage({defaultMessage: 'Přihlášení proběhlo úspěšně'}),
                            duration: 5000,
                            header: intl.formatMessage({defaultMessage: 'Úspěch'}),
                            color: 'green',
                        });
                        dataLayerPush({
                            event: 'prihlaseni_uspesne',
                            touchpoint: getTouchPoint(id),
                        });
                        if (redirect && response?.url) {
                            void router.push(response.url);
                        }
                        onSuccess?.();
                    }
                })
                .catch(() => {
                    actions.setSubmitting(false);
                });
        },
        [intl, onSuccess, redirect, toaster, router, personify, targetUrl, subscribe, executeRecaptcha],
    );

    return (
        <>
            <Typography as="h2" variant="h4" className="mb-1" textAlign="center">
                <FormattedMessage defaultMessage="Přihlaste se" />
            </Typography>

            {onRegister && <LoginRegisterToggle id={id} onRegister={onRegister} type={LoginRegisterToggleEnum.Login} />}

            <Typography as="p" variant="perex" color="grey-dark" className="mb-3">
                <FormattedMessage defaultMessage="Zadejte své přihlašovací údaje" />
            </Typography>

            <Formik
                innerRef={form}
                onSubmit={processLoginSubmit}
                initialValues={{
                    username: '',
                    password: '',
                    csrfToken: '',
                    callbackUrl: '',
                    target: '',
                    redirect: 'true',
                    json: 'false',
                    marketing: false,
                }}
                validationSchema={Yup.object({
                    username: Yup.string()
                        .email(intl.formatMessage({defaultMessage: 'Zadejte validní emailovou adresu'}))
                        .required(intl.formatMessage({defaultMessage: 'Povinné pole'})),
                    password: Yup.string().required(intl.formatMessage({defaultMessage: 'Povinné pole'})),
                    csrfToken: Yup.string(),
                    callbackUrl: Yup.string(),
                    target: Yup.string(),
                    marketing: Yup.boolean(),
                })}
            >
                {({handleSubmit}) => (
                    <Form onSubmit={handleSubmit} action="/api/auth/callback/credentials" method="post">
                        <Form.Group className="mb-4" controlId="email">
                            <Form.Label visuallyHidden>
                                <FormattedMessage defaultMessage="Vaše e-mailová adresa" />
                            </Form.Label>
                            <Input
                                name="username"
                                type="text"
                                autoComplete="email"
                                trimOnBlur
                                placeholder={intl.formatMessage({defaultMessage: 'Vaše e-mailová adresa'})}
                            />
                        </Form.Group>

                        <PasswordInput
                            className="mb-"
                            name="password"
                            autoComplete="current-password"
                            placeholder={intl.formatMessage({defaultMessage: 'Vaše heslo'})}
                            label={intl.formatMessage({defaultMessage: 'Vaše heslo'})}
                        />

                        {onForgottenPassword && (
                            <Box className="d-flex justify-content-end mb-4">
                                <InfoText iconName={false} className="mb-0">
                                    <a role="button" onClick={onForgottenPassword} className="link">
                                        <FormattedMessage defaultMessage="Zapomněli jste heslo?" />
                                    </a>
                                </InfoText>
                            </Box>
                        )}

                        <Form.Group controlId="marketing">
                            <CheckboxInput
                                name="marketing"
                                placeholder={intl.formatMessage({defaultMessage: 'Vložte e-mailovou adresu'})}
                                className="mb-2"
                                label={
                                    <InfoTooltip
                                        trigger={isMdDown ? ['hover', 'focus', 'click'] : ['click', 'hover']}
                                        content={
                                            <FormattedMessage
                                                defaultMessage={
                                                    'Zaškrtnutím souhlasíte se zpracováním osobních údajů za účelem nabízení a zpracováním ' +
                                                    'marketingových nabídek společnosti Bezrealitky s.r.o.. Máte právo svůj souhlas ' +
                                                    'odvolat. Více informací v <terms>zásadách zpracování osobních údajů</terms>'
                                                }
                                                values={{
                                                    terms: (...chunks: any) => (
                                                        <Link
                                                            href={getLocalizedRoute(routes.page, locale, {
                                                                uri: 'smluvni-podminky/zasady-zpracovani-osobnich-udaju-marketing',
                                                            })}
                                                            passHref
                                                        >
                                                            {chunks}
                                                        </Link>
                                                    ),
                                                }}
                                            />
                                        }
                                    >
                                        <FormattedMessage defaultMessage="Odebírat novinky z realitního trhu" />
                                    </InfoTooltip>
                                }
                            />
                        </Form.Group>

                        <HiddenInput name="csrfToken" />
                        <HiddenInput name="callbackUrl" />
                        <HiddenInput name="target" />
                        <HiddenInput name="redirect" />
                        <HiddenInput name="json" />

                        <SubmitButton className="w-100">
                            <FormattedMessage defaultMessage="Přihlaste se" />
                        </SubmitButton>
                    </Form>
                )}
            </Formik>

            <Box className="mt-5">
                <RecaptchaClaim />
            </Box>

            <Divider className="my-10" color="grey-light">
                <FormattedMessage defaultMessage="Nebo" />
            </Divider>

            <Button
                className="w-100 mb-4"
                variant="outline-secondary"
                onClick={() => void signIn('facebook', {redirect: true, callbackUrl: targetUrl})}
            >
                <FormattedMessage defaultMessage="Pokračovat pomocí Facebooku" />
            </Button>
            <Button
                className="w-100"
                variant="outline-primary"
                onClick={() => void signIn('google', {redirect: true, callbackUrl: targetUrl})}
            >
                <FormattedMessage defaultMessage="Pokračovat pomocí Google" />
            </Button>
        </>
    );
};
