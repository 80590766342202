import {utf8_to_b64} from '@utils/base64';
import {AdvertImportType, Disposition, EstateType, Locale, OfferType, Ownership} from '.cache/__types__';
import {imperativeTranslations} from '@constants/Disposition';
import {IntlShape} from 'react-intl';
import {NextRouter} from 'next/router';
import {getLocalizedRoute, routes} from '@utils/routes';
import {LocaleType} from 'src/types/general';

export type NemoreportData = {
    id?: string | null;
    uri?: string | null;
    price?: number | null;
    disposition?: Disposition | null;
    estateType?: EstateType | null;
    offerType?: OfferType | null;
    city?: string | null;
    street?: string | null;
    houseNumber?: string | null;
    locale?: Locale | null;
    zip?: string | null;
    addressPointId?: string | null;
    houseUnit?: string | null;
    type?: AdvertImportType | null;
    ownership?: Ownership | null;
};

export type NemoreportCompatibleData = {
    id: string;
    uri: string;
    price: number;
    disposition: Exclude<Disposition, Disposition.Undefined>;
    offerType: OfferType.Prodej;
    city: string;
    locale: Locale.Cs;
    addressPointId: string;
    houseNumber: string;
} & NemoreportData;

export type NemoreportFullFlatData = {
    estateType: EstateType.Byt;
    houseUnit: string;
} & NemoreportCompatibleData;

export type NemoreportFullNonFlatData = {
    estateType: Exclude<EstateType, EstateType.Byt>;
    houseUnit?: never;
} & NemoreportCompatibleData;

export type NemoreportFullData = NemoreportFullFlatData | NemoreportFullNonFlatData;

export const isNemoreportCompatible = (advert: NemoreportData): advert is NemoreportCompatibleData => {
    return (
        !!advert.id &&
        !!advert.uri &&
        !!advert.price &&
        !!advert.disposition &&
        advert.disposition !== Disposition.Undefined &&
        [OfferType.Prodej].includes(advert.offerType ?? OfferType.Undefined) &&
        [EstateType.Dum, EstateType.Byt, EstateType.RekreacniObjekt].includes(advert.estateType ?? EstateType.Undefined) &&
        advert.locale === Locale.Cs &&
        !!advert.addressPointId &&
        !!advert.city &&
        !!advert.houseNumber &&
        (!advert.type ||
            advert.type === AdvertImportType.Undefined ||
            (advert.type === AdvertImportType.Maxima && (advert.estateType !== EstateType.Byt || !!advert.houseUnit))) &&
        advert.ownership !== Ownership.Druzstevni
    );
};

export const isNemoreportFullData = (advert: NemoreportData): advert is NemoreportFullData => {
    return isNemoreportCompatible(advert) && (advert.estateType !== EstateType.Byt || !!advert.houseUnit);
};

export type PropertyTypeType = 'house' | 'houseUnit' | 'land';

export const getNemoreportLandingUri = (
    referer: string,
    advertId: string,
    advertUrl: string,
    address: {town: string; street?: string; zip?: string; houseNumber: string},
    ruianId: string,
    price: number,
    layout: string,
    userDetail: {id: string; email: string; url: string} | undefined,
    houseUnit: string | undefined = undefined,
    propertyType: PropertyTypeType,
) => {
    const url = new URL(process.env.NEXT_PUBLIC_NEMOREPORT_LANDING_URI + '/?do=propertySelectControl-LoadBZR');
    const params = url.searchParams;
    params.set('referer', referer);
    params.set(
        'data',
        utf8_to_b64(
            JSON.stringify({
                advertId,
                advertUrl,
                address,
                ruianId,
                price,
                layout,
                userDetail,
            }),
        ),
    );
    if (houseUnit) {
        params.set('houseUnit', houseUnit);
    }
    params.set('propertyType', propertyType);

    return url.toString();
};

export const getNemoreportLandingLeadUri = (
    referer: string,
    advertId: string | null,
    advertUrl: string,
    address: {town: string; street?: string; zip?: string; houseNumber: string},
    ruianId: string,
    // price: number,
    // layout: string,
    userDetail: {id: string; email: string; url: string} | undefined,
    houseUnit: string | undefined = undefined,
    propertyType: PropertyTypeType,
) => {
    const advertIdObj = advertId ? {advertId} : {};
    const advertUrlObj = advertUrl ? {advertUrl} : {};
    const url = new URL(process.env.NEXT_PUBLIC_NEMOREPORT_LANDING_URI + '/?do=propertySelectControl-LoadBZR');
    const params = url.searchParams;
    params.set('referer', referer);
    params.set(
        'data',
        utf8_to_b64(
            JSON.stringify({
                ...advertIdObj,
                ...advertUrlObj,
                address,
                ruianId,
                // price,
                // layout,
                userDetail,
            }),
        ),
    );
    if (houseUnit) {
        params.set('houseUnit', houseUnit);
    }
    params.set('propertyType', propertyType);

    return url.toString();
};

export const getNemoreportLandingUriFromAdvert = (
    advert: NemoreportFullData,
    intl: IntlShape,
    user: {id: string; email: string} | null | undefined,
    referer: string,
    advertUrl: string,
) => {
    return getNemoreportLandingUri(
        referer,
        advert.id,
        advertUrl,
        {
            town: advert.city,
            street: advert.street ?? undefined,
            zip: advert.zip ?? undefined,
            houseNumber: parseHouseNumber(advert.houseNumber)!,
        },
        advert.addressPointId,
        advert.price,
        imperativeTranslations[advert.disposition as Disposition](intl),
        user
            ? {
                  id: user.id,
                  email: user.email,
                  url: advertUrl,
              }
            : undefined,
        advert.houseUnit,
        advert.estateType === EstateType.Byt ? 'houseUnit' : 'house',
    );
};

export const getNemoReportUrls = (
    router: NextRouter,
    locale: LocaleType,
    user: {id?: string | null} | null,
    advert?: {uri: string} | null,
) => {
    const currentDomain = router.domainLocales?.find((d) => d.defaultLocale === router.locale);

    const refererUrl =
        `http${currentDomain?.http ? '' : 's'}://` +
        currentDomain?.domain +
        router.basePath +
        (user ? getLocalizedRoute(routes.myServices, locale) : router.asPath);

    const currentUrl = `http${currentDomain?.http ? '' : 's'}://` + currentDomain?.domain + router.basePath + router.asPath;

    const advertUrl = advert
        ? `http${currentDomain?.http ? '' : 's'}://` +
          currentDomain?.domain +
          router.basePath +
          getLocalizedRoute(routes.detail, locale, {slug: advert.uri})
        : '';

    return {refererUrl, currentUrl, advertUrl};
};

export const parseHouseNumber = (houseNumber?: string | null) => {
    if (!houseNumber) {
        return undefined;
    }

    return houseNumber.split('/')[0].startsWith('ev.') ? houseNumber.split('/')[0].substring(4) : houseNumber.split('/')[0];
};

export const parseCity = (address?: Record<string, string> | undefined) => {
    if (!address) {
        return undefined;
    }

    const city = address.city ?? address.town ?? address.village;

    if (city === 'Hlavní město Praha') {
        return 'Praha';
    }

    return city;
};

export const parseRuianId = (extratags: Record<string, any> | undefined) => {
    return extratags ? extratags['uir_adr:ADRESA_KOD'] ?? extratags['ref:ruian'] ?? extratags['ref:ruian:addr'] : undefined;
};

export const parseStreet = (address?: Record<string, string> | undefined) => {
    if (!address) {
        return undefined;
    }

    return address.street ?? address.road;
};
